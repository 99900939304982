//
// simple wrapper around rollbar
//

import type { User } from "@/lib/models";
import Rollbar, { type Payload } from "rollbar";

export let rollbar: Rollbar | undefined;

export function init(accessToken: string, user?: User) {
  // prepare person for payload
  let person: Payload["person"] | undefined;
  if (user) {
    person = { id: user.id, name: user.name };
  }

  // now setup rollbar
  rollbar = new Rollbar({
    accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: import.meta.env.MODE,
      client: {
        javascript: {
          guess_uncaught_frames: true,
          source_map_enabled: true,
        },
      },
      person,
    },
  });

  // for testing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).rollbar = rollbar;
}

export function logout() {
  rollbar?.configure({ payload: { person: undefined } });
}

export function error(error: Error, info?: string) {
  rollbar?.error(error, { info });
}
