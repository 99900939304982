import type { ChartSeries } from "@/lib/models.js";
import type { ChartOptions } from "chart.js";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  SubTitle,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm.js";

//
// Style constants
//

const FONT_COLOR = "#0C0C0C";
const FONT_FAMILY = "Roboto Condensed";
const FONT_SIZE = 12;
const GRID_COLOR = "#E0E0E0";
const TOOLTIP_FONT_SIZE = 16;
const COLORS = "#1f78b4 #33a02c #b2df8a".split(" ");

//
// types
//

// simplified version of Chart.js's ChartDataset
export type ChartDataset = {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
};

// simplified version of Chart.js's ChartData
export type ChartData = {
  labels: string[];
  datasets: ChartDataset[];
};

//
// setup
//

export function initCharts() {
  ChartJS.register(
    BarElement,
    CategoryScale,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    SubTitle,
    TimeScale,
    Title,
    Tooltip,
  );
  ChartJS.defaults.font.family = FONT_FAMILY;
  ChartJS.defaults.font.size = FONT_SIZE;
  ChartJS.defaults.color = FONT_COLOR;
}

//
// data
//

export function buildChartData(data: ChartSeries[]): ChartData {
  // labels is the union of all the keys in the data objects
  const labels = Array.from(new Set(data.flatMap((series) => Object.keys(series.data))));

  const datasets = data.map((series, index) => ({
    label: series.label,
    data: labels.map((label) => series.data[label] ?? 0),

    // for bar chart
    backgroundColor: COLORS[index % COLORS.length],

    // for line chart
    borderColor: COLORS[index % COLORS.length],
  }));

  return { labels, datasets };
}

//
// chart options
//

type OptionParams = {
  mobile?: boolean;

  // Header for non-html legend. We set these when building png charts. On the
  // frontend, we render with html instead.
  legend?: boolean;
  subtitle?: string;
  title?: string;

  // for bar chart
  stacked?: boolean;

  // for line chart
  points?: boolean;
  rounded?: boolean;
};

export function barChartOptions({ legend, mobile, stacked, subtitle, title }: OptionParams): ChartOptions<"bar"> {
  return commonChartOptions({ legend, mobile, stacked, subtitle, title });
}

export function lineChartOptions({ legend, mobile, points, rounded, stacked, subtitle, title }: OptionParams) {
  return {
    ...commonChartOptions({ legend, mobile, stacked, subtitle, title }),

    //
    // show/hide points
    //

    pointRadius: points ? undefined : 0,
    pointHoverRadius: points ? undefined : 0,

    //
    // rounded lines
    //

    cubicInterpolationMode: rounded ? "monotone" : undefined,
    tension: rounded ? 0.4 : 0,
  };
}

function commonChartOptions(options: OptionParams): ChartOptions<"bar"> & ChartOptions<"line"> {
  const titleBottomPadding = options.mobile ? 12 : 16;

  let aspectRatio: number;
  if (options.legend) {
    // Need more vertical space for legend
    aspectRatio = 2;
  } else {
    // Less vertical space without a legend, but squish the chart a bit on mobile
    aspectRatio = options.mobile ? 2.5 : 2.25;
  }

  return {
    animation: false,

    aspectRatio,

    interaction: {
      // Show all categories in tooltip
      mode: "index",
    },

    plugins: {
      legend: {
        display: !!options.legend,
        labels: {
          boxHeight: options.mobile ? 8 : 12,
          boxWidth: options.mobile ? 8 : 12,
          font: {
            size: options.mobile ? 10 : 12,
          },
        },
      },
      subtitle: {
        align: "start",
        display: !!options.subtitle,
        text: options.subtitle,
        font: {
          weight: "normal",
        },
        padding: {
          top: 2,
          bottom: options.legend ? 0 : titleBottomPadding,
        },
      },
      title: {
        align: "start",
        display: !!options.title,
        text: options.title,
        font: {
          size: 14,
          weight: "bold",
        },
        padding: {
          bottom: options.subtitle || options.legend ? 0 : titleBottomPadding,
        },
      },
      tooltip: {
        enabled: !options.mobile,

        //
        // styles
        //

        backgroundColor: "#fefdfb", // off-white
        bodyColor: FONT_COLOR,
        bodyFont: { size: TOOLTIP_FONT_SIZE },
        borderColor: "#dad7ca", // matches site border color
        borderWidth: 1,
        boxHeight: TOOLTIP_FONT_SIZE,
        boxWidth: TOOLTIP_FONT_SIZE,
        footerColor: FONT_COLOR,
        footerFont: { size: TOOLTIP_FONT_SIZE, weight: "normal" },
        titleMarginBottom: 8,
        footerMarginTop: 8,
        padding: 12,
        titleColor: FONT_COLOR,
        titleFont: { size: TOOLTIP_FONT_SIZE, weight: "bold" },

        // Need this plus labelPointStyle to get rid of white outline on boxes
        // https://github.com/chartjs/Chart.js/discussions/10923
        usePointStyle: true,

        // Don't show zeros in tooltip
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filter: (tooltipItem: any) => tooltipItem.raw > 0,

        // Reverse the order of the items in the tooltip to match the order they
        // appear in the stacked bar chart (first item at the bottom)
        itemSort: (a, b) => {
          return b.datasetIndex - a.datasetIndex;
        },

        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          footer: (tooltipItems: any[]) => {
            let sum = 0;

            tooltipItems.forEach((tooltipItem) => {
              sum += tooltipItem.parsed.y;
            });
            return `Total - ${sum}`;
          },

          // See usePointStyle comment
          labelPointStyle: () => {
            return {
              pointStyle: "rect",
              rotation: 0,
            };
          },

          // Use "-" instead of ":" on labels
          label: function (context) {
            let label = context.dataset.label;

            if (label && context.parsed.y) {
              label += ` - ${context.parsed.y}`;
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        grid: {
          display: false,
        },
        stacked: !!options.stacked,
        time: {
          minUnit: "day",
          tooltipFormat: "MMM Do YYYY",
        },
        ticks: {
          maxRotation: 0, // disable rotation
        },
        afterBuildTicks: (scale) => {
          // If there are only a few ticks, it looks better to label them all
          const maxTicks = options.mobile ? 5 : 10;

          if (scale.ticks.length <= maxTicks) return;

          // There are too many ticks to label them all, so space out the labels
          // to an attractive number.
          const desiredTicks = options.mobile ? 3 : 5;
          const width = scale.ticks.length - 1; // fencepost problem
          const spaceBetweenTicks = Math.ceil(width / (desiredTicks - 1));

          scale.ticks = scale.ticks.filter((_, index) => {
            // Always include the last tick
            if (index === scale.ticks.length - 1) return true;

            // Never get too close to the last tick
            if (index > scale.ticks.length - 3) return false;

            // Space the remaining ticks evenly
            return index % spaceBetweenTicks === 0;
          });
        },
      },
      y: {
        position: "right",
        border: { display: false },
        beginAtZero: true,
        grid: {
          color: GRID_COLOR,
        },
        stacked: !!options.stacked,
        ticks: {
          autoSkip: false, // simplify tick math since maxTicksLimit is set
          maxTicksLimit: options.mobile ? 3 : 5, // ensure plenty of space between ticks
          precision: 0, // integer only
        },
      },
      // Workaround for layout bug. Without a left axis, the x-axis overflows to the left
      // of the allowed chart area. We add a left axis with no border and no ticks. It
      // doesn't take up any space, but keeps the rest of the chart in bounds.
      left: {
        display: true,
        type: "linear",
        position: "left",
        border: {
          display: false,
        },
        afterBuildTicks: (scale) => {
          scale.ticks = []; // no ticks so that nothing is drawn
        },
      },
    },
    layout: {
      padding: 0,
    },
  };
}
