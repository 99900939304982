import * as VueRouter from "vue-router";

declare module "vue-router" {
  interface RouteMeta {
    requireLogin?: boolean; // if true, redirect to login if not logged in
    requireLogout?: boolean; // if true, redirect to root if logged in
    title?: string; // set document.title
  }
}

const NotFound = defineComponent({
  created() {
    window.location.href = "/404.html";
  },
  render() {
    return "";
  },
});

//
// routes
// Note: if you want to use a per-page navigation guard, see DashPage for an example.
//

const routes: VueRouter.RouteRecordRaw[] = [
  // home
  { path: Routes.about(), component: () => import("@/components/home/HomeAboutPage.vue") },
  {
    path: Routes.faq(),
    component: () => import("@/components/home/HomeFAQPage.vue"),
    meta: { title: "FAQ" },
  },
  { path: Routes.root(), component: () => import("@/components/home/HomePage.vue") },

  // dash
  {
    path: Routes.alert(":id"),
    component: () => import("@/components/alert/AlertPage.vue"),
    meta: { requireLogin: true },
  },
  {
    path: Routes.create(),
    component: () => import("@/components/alert/AlertCreatePage.vue"),
    meta: { title: "Create Alert", requireLogin: true },
  },
  {
    path: Routes.edit(":id"),
    component: () => import("@/components/alert/AlertEditPage.vue"),
    meta: { title: "Edit Alert", requireLogin: true },
  },
  {
    path: Routes.dashboard(),
    component: () => import("@/components/dash/DashPage.vue"),
    meta: { title: "Dashboard", requireLogin: true },
  },
  {
    path: Routes.settings(),
    component: () => import("@/components/settings/SettingsPage.vue"),
    meta: { title: "Settings", requireLogin: true },
  },

  // auth
  {
    path: Routes.login(),
    component: () => import("@/components/auth/AuthLoginPage.vue"),
    meta: { title: "Login", requireLogout: true },
  },
  {
    path: Routes.signup(),
    component: () => import("@/components/auth/AuthSignupPage.vue"),
    meta: { title: "Sign Up", requireLogout: true },
  },
  {
    path: Routes.forgot(),
    component: () => import("@/components/auth/AuthForgotPage.vue"),
    meta: { title: "Forgot Password", requireLogout: true },
  },
  {
    path: Routes.reset(),
    component: () => import("@/components/auth/AuthResetPage.vue"),
    meta: { title: "Reset Password", requireLogout: true },
  },

  // dev
  {
    path: "/dev/forms",
    component: () => import("@/components/dev/DevFormsPage.vue"),
    meta: { title: "Forms", requireLogin: true },
  },

  //
  // 404 catch-all
  //

  { path: "/:pathMatch(.*)+", component: NotFound },
];

//
// createRouter (and hooks)
//

export function createRouter(): VueRouter.Router {
  const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
    scrollBehavior(_to, _from, savedPosition) {
      // https://router.vuejs.org/guide/advanced/scroll-behavior
      return savedPosition ? savedPosition : { top: 0 };
    },
  });

  // redirect to login if not logged in
  router.beforeEach(async (to) => {
    if (to.meta.requireLogin) {
      const authStore = useAuthStore();
      if (authStore.loggedIn) return;
      return Routes.login();
    }
    if (to.meta.requireLogout) {
      const authStore = useAuthStore();
      if (!authStore.loggedIn) return;
      return Routes.dashboard();
    }
  });

  // set document.title
  router.afterEach((to) => {
    let title = "Alertmouse";
    if (to.meta.title) {
      title = `${title} - ${to.meta.title}`;
    }
    document.title = title;
  });

  return router;
}
