import { initCharts } from "@/lib/chart";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

//
// Global initialization code for app and tests
//

export default function init() {
  initCharts();
  initDayjs();
}

function initDayjs() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
}
