<template>
  <AppHeader />
  <div class="min-h-[var(--main-min-height)]">
    <!-- https://vuejs.org/guide/built-ins/suspense#combining-with-other-components -->
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Suspense>
          <component :is="Component" :key="route.path" />
        </Suspense>
      </template>
    </RouterView>
  </div>
  <AppFooter />

  <!-- flash -->
  <AppFlash />
</template>

<script setup lang="ts">
import AppFlash from "./AppFlash.vue";
import AppHeader from "./AppHeader.vue";
import AppFooter from "./AppFooter.vue";

//
// state
//

const route = useRoute();
</script>
