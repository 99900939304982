const routes = {
  // landing and related
  root: () => "/",
  about: () => "/about",

  alert: (id: string) => `/alerts/${id}`,
  create: () => "/create",
  edit: (id: string) => `/edit/${id}`,
  dashboard: () => "/dashboard",
  settings: () => "/settings",
  faq: () => "/faq",

  // downloads
  alertChart: (alertId: string) => `/charts/alerts/${alertId}.png`,

  // auth
  login: () => "/login",
  signup: () => "/signup",
  forgot: () => "/forgot",
  reset: () => "/reset",
};

export default routes;
