<template>
  <div
    class="app-header fixed left-0 right-0 top-0 z-50 flex h-[var(--header-height)] items-center justify-between gap-2 px-[var(--layout-padding-x)] backdrop-blur-sm"
    :class="{ 'border-b': scrolled, 'app-header-emergency': !!emergency }"
  >
    <RouterLink :to="home" class="flex-none">
      <img src="@/assets/wordmark.svg" class="h-4 sm:h-8" />
    </RouterLink>

    <!-- emergency message -->
    <div v-if="emergency" class="truncate text-red-800">{{ emergency }}</div>

    <!-- links -->
    <nav class="flex items-center gap-3 rounded-2xl border px-3 py-2 text-xs sm:gap-4 sm:px-4 sm:text-sm">
      <template v-if="!authStore.loggedIn">
        <RouterLink :to="Routes.signup()" class="btn btn-primary">Sign up</RouterLink>
        <RouterLink :to="home">Home</RouterLink>
        <RouterLink :to="Routes.about()">About</RouterLink>
      </template>
      <template v-else>
        <RouterLink :to="home">
          <span class="sm:hidden">Dash</span>
          <span class="hidden sm:inline">Dashboard</span>
        </RouterLink>
        <RouterLink :to="Routes.settings()">Settings</RouterLink>
        <a class="text-nowrap" @click="onLogout">Log out</a>
      </template>
    </nav>
  </div>
</template>

<script setup lang="ts">
import type { Hello } from "@/lib/models";
import * as Rollbar from "@/lib/rollbar";
import { apiAuth } from "@/vendor/api";
import { useEventListener } from "@vueuse/core";

const hello = inject<Hello>("hello");

//
// state
//

const authStore = useAuthStore();
const scrolled = ref<boolean>();
const home = computed(() => (authStore.loggedIn ? Routes.dashboard() : Routes.root()));
const emergency = computed(() => hello?.emergency);

//
// events
//

async function onLogout() {
  await apiAuth.logout();
  Rollbar.logout();

  // Do a full navigation here to ensure that all state is reset
  window.location.href = emergency.value ? "/admin" : Routes.root();
}

// note when navbar becomes sticky
useEventListener("scroll", () => (scrolled.value = window.scrollY > 24));
</script>

<style scoped lang="postcss">
.app-header {
  @apply bg-paper/80;

  &.app-header-emergency {
    @apply bg-red-300;
  }
}

a:not(.btn) {
  @apply link-hover link;
}
.btn {
  @apply btn-xs h-7 min-h-7 rounded-xl px-3;
}
</style>
