// JsFromRoutes CacheKey cc52b45c0750f52b311daffff33251ef
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  hello: /* #__PURE__ */ definePathHelper('get', '/api/auth/hello'),
  oauth: /* #__PURE__ */ definePathHelper('get', '/api/auth/oauth/:provider'),
  signup: /* #__PURE__ */ definePathHelper('post', '/api/auth/signup'),
  login: /* #__PURE__ */ definePathHelper('post', '/api/auth/login'),
  logout: /* #__PURE__ */ definePathHelper('post', '/api/auth/logout'),
  forgot: /* #__PURE__ */ definePathHelper('post', '/api/auth/forgot'),
  reset: /* #__PURE__ */ definePathHelper('post', '/api/auth/reset'),
  resetCheck: /* #__PURE__ */ definePathHelper('post', '/api/auth/reset_check'),
}
