<template>
  <Transition>
    <div
      v-if="text"
      :key="trigger"
      ref="root"
      class="pointer-events-none fixed left-0 right-0 top-4 z-50 flex justify-center sm:top-10"
    >
      <div
        class="flex min-h-16 w-[20rem] items-center justify-center rounded-2xl bg-indigo-700 p-4 text-center text-sm leading-5 text-white shadow-2xl transition-all sm:w-[24rem]"
      >
        <span>{{ text }}</span>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { emitter } from "@/lib/util";

// how long should we show the message?
const DURATION = 5000;

//
// state
//

const text = ref<string>();
const trigger = ref(0); // this is used to reset the animation
const root = useTemplateRef("root");
let timer: ReturnType<typeof setTimeout> | undefined;
let flashedAt = 0;

//
// actions
//

function show(message: string) {
  flashedAt = Date.now();

  // show
  trigger.value += 1; // restart transition if in progress
  text.value = message;

  // hide later
  clearTimeout(timer);
  timer = setTimeout(hide, DURATION);
}

function hide() {
  text.value = undefined;
  clearTimeout(timer);
  timer = undefined;
}

function hideInstantly() {
  const el = root.value;
  if (!el) return;
  const CLASS = "!duration-0";
  el.classList.add(CLASS);
  hide();
  setTimeout(() => el.classList.remove(CLASS), 50);
}

//
// events
//

const router = useRouter();
router.beforeEach(() => {
  // We may want to hide an existing flash message whenever we push a route.
  // This could be complicated because we typically show flash just before
  // pushing a route! Hide on route change only if already visible for a bit.
  if (flashedAt && Date.now() - flashedAt > 1000) {
    hideInstantly();
  }
});

onMounted(() => emitter.on("flash", show));
</script>

<style scoped lang="postcss">
.v-enter-active {
  @apply delay-200 duration-500;
}
.v-leave-active {
  @apply duration-300;
}
.v-enter-from,
.v-leave-to {
  @apply top-0 opacity-0;
}
</style>
