import type { ResponseError } from "@js-from-routes/client";
import dayjs from "dayjs";
import { isString } from "es-toolkit";
import mitt from "mitt";

/* eslint-disable @typescript-eslint/no-explicit-any */

/* create a URL with query params */
export function createURL(str: string, q: Record<string, unknown> | undefined): URL {
  let url = str;
  if (q && Object.keys(q).length) {
    const present = Object.entries(q).filter(([_, v]) => v !== null && v !== undefined);
    const strings = present.map(([k, v]) => [k, String(v)]);
    if (strings.length) {
      url = `${url}?${new URLSearchParams(Object.fromEntries(strings))}`;
    }
  }
  return new URL(url);
}

type EmitterEvents = {
  flash: string;
};

/* Global event emitter, be sure to prefix your event names! */
export const emitter = mitt<EmitterEvents>();

/* convert an exception/ResponseError into an error message */
export function getError(err: unknown): string | undefined {
  if (!(err instanceof Error)) return `${err}`; // impossible?
  if ("body" in err) {
    const re = err as ResponseError;
    if (re.body?.error) {
      return re.body.error;
    }
  }
  return err.message;
}

/* show flash message */
export function flash(message: string) {
  emitter.emit("flash", message);
}

/* Get a route param as a string rather than string | string[] */
export function getRouteParam(name: string): string {
  const route = useRoute();
  const value = route.params[name];
  if (!isString(value)) {
    throw new Error(`invalid ${name}`);
  }
  return value;
}

/* run fn slowly */
export async function slowly<T extends () => Promise<any>>(fn: T, duration = 800): Promise<ReturnType<T>> {
  const start = Date.now();
  const result = await fn();
  const sleep = duration - (Date.now() - start);
  if (sleep > 0) {
    await timeout(sleep);
  }
  return result;
}

/* like ruby squish */
export function squish(s: string) {
  return s.trim().replace(/\s+/g, " ");
}

/* async timeout */
export function timeout(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

//
// date helpers
// see https://day.js.org/docs/en/display/format
//

/** Nov 2024 (UTC-based) */
export function jan2000(date: Date): string {
  return dayjs(date).tz("UTC").format("MMM YYYY");
}

/** Nov 1st 2024 (UTC-based) */
export function jan12000(date: Date): string {
  return dayjs(date).tz("UTC").format("MMM Do YYYY");
}
